import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import "../style/GoToTop.css"
import { FaArrowUpLong } from "react-icons/fa6"
import { useScrollToTopAndNavigate } from "../utils/utils.jsx"
function GoToTop() {
  const [showButton, setShowButton] = useState(true)
  const location = useLocation()
  const handleGoToTopClick = useScrollToTopAndNavigate("/")

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    }

    window.addEventListener("scroll", handleScroll)
    handleScroll()

    return () => window.removeEventListener("scroll", handleScroll)
  }, [location])
  console.log("window.scrollY", window.scrollY, window.innerHeight)

  return (
    showButton && (
      <button className="go-to-top-button" onClick={handleGoToTopClick}>
        <FaArrowUpLong className="go-to-top" />
      </button>
    )
  )
}

export default GoToTop
