import React, { useState } from "react"
import { MdOutlineDashboard } from "react-icons/md"
import { TbTemplate } from "react-icons/tb"
import logo from "../images/favicon.png"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { LOGOUT_REQUEST } from "../redux/auth/authAction.jsx"
import { IoCreateOutline } from "react-icons/io5"

const Sidebar = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const locationname = useLocation()
  const [templateMenuOpen, setTemplateMenuOpen] = useState(false)
  const [selectedIcon, setSelectedIcon] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleIconClick = (iconName) => {
    setSelectedIcon(iconName)
    if (iconName === "edit_template") {
      setTemplateMenuOpen(!templateMenuOpen)
      setMenuOpen(false) // Close user menu if open
    } else {
      setTemplateMenuOpen(false)
    }
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
    setTemplateMenuOpen(false) // Close template menu if open
  }

  const handleLogout = () => {
    dispatch({ type: LOGOUT_REQUEST })
  }

  const handleTemplateClick = (route) => {
    navigate(route)
    setTemplateMenuOpen(false)
  }

  return (
    <>
      <div className="bg-white-100 text-black-100 h-screen w-auto border-solid border-r-2 border-black-200 flex flex-col justify-between items-center">
        <div>
          <img src={logo} alt="logo" className="py-12 px-4" width="80" height="80" />
          <div className="text-2xl text-grey_font-100 flex flex-col items-center gap-10">
            <Link to="/dashboard" title="Dashboard">
              <button
                className={
                  locationname.pathname === "dashboard"
                    ? "bg-black-400 text-primary-100 rounded-md p-2"
                    : "bg-transparent"
                }
                aria-label="Dashboard"
                type="button"
              >
                <MdOutlineDashboard />
              </button>
            </Link>
            <Link to="/template" title="Template">
              <button
                className={
                  locationname.pathname === "/template"
                    ? "bg-black-400 text-primary-100 rounded-md p-2"
                    : "bg-transparent"
                }
                aria-label="Template"
                type="button"
              >
                <TbTemplate />
              </button>
            </Link>
            <div>
              <button
                className={
                  selectedIcon === "edit_template"
                    ? "bg-black-400 text-primary-100 rounded-md p-2"
                    : "bg-transparent"
                }
                onClick={() => handleIconClick("edit_template")}
                aria-label="Edit Template"
                type="button"
              >
                <IoCreateOutline />
              </button>
              {templateMenuOpen && (
                <div
                  className="flex flex-col items-start text-center justify-center absolute top-30 left-16 bg-white-100 border border-gray-300 shadow-lg rounded-md text-sm z-40"
                  role="button"
                  tabIndex="0"
                >
                  <button
                    className="block text-gray-700 hover:bg-gray-100 border-b-2 p-2 w-full text-left"
                    onClick={() => handleTemplateClick("/modifytemplate")}
                  >
                    Create Template
                  </button>
                  <button
                    className="block text-gray-700 hover:bg-gray-100 p-2 w-full text-left"
                    onClick={() => handleTemplateClick("/modifytemplate")}
                  >
                    Manage Template
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="pb-10 flex flex-col items-center gap-5 text-grey_font-100 relative">
          <button
            className="w-12 h-12 rounded-full p-0"
            onClick={toggleMenu}
            aria-label="User menu"
            type="button"
          >
            <img
              className="w-full h-full rounded-full"
              src="https://www.brighterdaysgriefcenter.org/wp-content/uploads/2017/01/team.jpg"
              alt="Rounded avatar"
            />
          </button>
          {menuOpen && (
            <div
              className="flex flex-col items-start text-center justify-center absolute bottom-4 left-16 bg-white-100 border border-gray-300 shadow-lg rounded-md bg-white-100 py-1 w-28 z-40"
              role="button"
              tabIndex="0"
            >
              <button
                className="block text-gray-700 hover:bg-gray-100 border-b-2 p-2 w-full text-left"
                onClick={() => console.log("Profile clicked")}
              >
                Profile
              </button>
              <button
                className="block text-gray-700 hover:bg-gray-100 p-2 w-full text-left"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Sidebar
