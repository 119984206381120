import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import "../style/Features.css"
import { MdOutlineEmail } from "react-icons/md"
import feature1 from "../images/static home/feature1.png"
import feature2 from "../images/static home/feature2.png"
import feature3 from "../images/static home/feature3.png"
import feature4 from "../images/static home/feature4.png"

const Features = () => {
  // Detect mobile view with state
  const [, setIsMobile] = useState(window.innerWidth <= 768)

  // Update isMobile on window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  // Intersection Observer hooks
  const [ref1, inView1] = useInView({ triggerOnce: false, threshold: 0.1 })
  const [ref2, inView2] = useInView({ triggerOnce: false, threshold: 0.1 })
  const [ref3, inView3] = useInView({ triggerOnce: false, threshold: 0.1 })
  const [ref4, inView4] = useInView({ triggerOnce: false, threshold: 0.1 })

  // Simplified animation
  const simpleAnimation = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
  }

  return (
    <div className="features-container" id="features">
      <div className="features-title">
        <h4>Features</h4>
        {/* <h5>Analytics that feels like it’s from the future</h5> */}
        <p>
          Powerful, self-serve product and growth analytics to help you convert,
          engage, and retain more users. Trusted by over 4,000 startups.
        </p>
      </div>
      <div className="features-main">
        <div className="features-content" ref={ref1}>
          <motion.div
            initial="hidden"
            animate={inView1 ? "visible" : "hidden"}
            variants={simpleAnimation}
            className="features-text"
          >
            <div className="icon">
              <MdOutlineEmail size={30} />
            </div>
            <h5>
              Reach where your Customers are most active Promote and sell your
              Products on WhatsApp using Catalogs feature Redirect customers from FB
              Ads using Click to WhatsApp - CTWA
            </h5>
            <p>
              Whether you have a team of 2 or 200, our shared team inboxes keep
              everyone on the same page and in the loop.
            </p>
            <ul className="features-list">
              <li>Leverage automation to move fast</li>
              <li>Always give customers a human to chat to</li>
              <li>Automate customer support and close leads faster</li>
            </ul>
          </motion.div>
          <motion.div
            initial="hidden"
            animate={inView1 ? "visible" : "hidden"}
            variants={simpleAnimation}
            className="features-image"
          >
            <img src={feature1} alt="Feature 1" />
          </motion.div>
        </div>

        <div className="features-content" ref={ref2}>
          <motion.div
            initial="hidden"
            animate={inView2 ? "visible" : "hidden"}
            variants={simpleAnimation}
            className="features-image"
          >
            <img src={feature3} alt="Feature 3" />
          </motion.div>
          <motion.div
            initial="hidden"
            animate={inView2 ? "visible" : "hidden"}
            variants={simpleAnimation}
            className="features-text"
          >
            <div className="icon">
              <MdOutlineEmail size={30} />
            </div>
            <h5>
              Leverage automation to move fast Always give customers a human to chat
              to Automate customer support and close leads faster
            </h5>
            <p>
              Whether you have a team of 2 or 200, our shared team inboxes keep
              everyone on the same page and in the loop.
            </p>
            <ul className="features-list">
              <li>Leverage automation to move fast</li>
              <li>Always give customers a human to chat to</li>
              <li>Automate customer support and close leads faster</li>
            </ul>
          </motion.div>
        </div>

        <div className="features-content" ref={ref3}>
          <motion.div
            initial="hidden"
            animate={inView3 ? "visible" : "hidden"}
            variants={simpleAnimation}
            className="features-text"
          >
            <div className="icon">
              <MdOutlineEmail size={30} />
            </div>
            <h5>
              Increase your online sales with timely nudges Use WhatsApp flows to
              create interactive customer experience Create personalized messaging
              for retargeting
            </h5>
            <p>
              Our platform is designed with simplicity in mind, ensuring ease of use
              for all levels of users.
            </p>
            <ul className="features-list">
              <li>Leverage automation to move fast</li>
              <li>Always give customers a human to chat to</li>
              <li>Automate customer support and close leads faster</li>
            </ul>
          </motion.div>
          <motion.div
            initial="hidden"
            animate={inView3 ? "visible" : "hidden"}
            variants={simpleAnimation}
            className="features-image"
          >
            <img src={feature2} alt="Feature 2" />
          </motion.div>
        </div>
      </div>
      <div className="features-end" ref={ref4}>
        <div className="tittle">
          <h5>Cutting-edge features for advanced analytics</h5>
          <p>
            Powerful, self-serve product and growth analytics to help you convert,
            engage, and retain more users. Trusted by over 4,000 startups.
          </p>
        </div>
        <motion.div
          initial="hidden"
          animate={inView4 ? "visible" : "hidden"}
          variants={simpleAnimation}
          className="features-image"
        >
          <img src={feature4} alt="Feature 4" height={1000} width={1000} />
        </motion.div>
      </div>
    </div>
  )
}

export default Features
