import React from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import "../style/Navbar.css"
import logo from "../images/static home/logo.png"
import { useScrollToTopAndNavigate } from "../utils/utils.jsx"
import { useUIContext } from "../context/BasicProvider.jsx"

const Navbar = () => {
  const { selectedNavbar, setSelectedNavbar } = useUIContext()
  const handleGoToTopClick = useScrollToTopAndNavigate("/")
  const location = useLocation()
  const navigate = useNavigate()

  const handleScroll = (event, target) => {
    event.preventDefault()
    setSelectedNavbar(target)
    if (location.pathname !== "/") {
      navigate("/")
      setTimeout(() => {
        scrollToTarget(target)
      }, 100)
    } else {
      scrollToTarget(target)
    }
  }

  const scrollToTarget = (target) => {
    const element = document.getElementById(target)
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 50,
        behavior: "smooth",
      })
    }
  }

  return (
    <nav className="navbar">
      <div>
        <img src={logo} className="navbar-logo" alt="logo" />
      </div>
      <ul className="navbar-links">
        <button
          onClick={() => {
            setSelectedNavbar("home")
            handleGoToTopClick()
          }}
        >
          <Link to="/" className={selectedNavbar === "home" ? "active" : ""}>
            Home
          </Link>
        </button>
        <li>
          <a
            href="/features"
            onClick={(e) => handleScroll(e, "features")}
            className={selectedNavbar === "features" ? "active" : ""}
          >
            Features
          </a>
        </li>
        <li>
          <Link
            onClick={() => {
              handleGoToTopClick()
              setSelectedNavbar("contact")
            }}
            to="/contact"
            className={selectedNavbar === "contact" ? "active" : ""}
          >
            Contact Us
          </Link>
        </li>
      </ul>
      <div className="buttons">
        <Link to="/contact" style={{ textDecoration: "none" }}>
          <div className="button">BOOK LIVE DEMO</div>
        </Link>
        <Link to="/login" style={{ textDecoration: "none" }}>
          <div className="button">Login</div>
        </Link>
      </div>
    </nav>
  )
}

export default Navbar
