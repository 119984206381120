import React from "react"
import TitleHeader from "../component/global/TitleHeader.jsx"
import UserProfile from "../component/global/UserProfile.jsx"

const Topbar = ({ title }) => {
  return (
    <>
      <div className="flex justify-between items-center flex-wrap gap-3">
        <TitleHeader title={title} />
        <UserProfile />
      </div>
    </>
  )
}

export default Topbar
