import {
  FaTextHeight,
  FaImage,
  FaVideo,
  FaFileAlt,
  FaMapMarkerAlt,
} from "react-icons/fa"
import { BsFillReplyFill } from "react-icons/bs"
import { PiArrowSquareInLight } from "react-icons/pi"
import { MdCall, MdOutlineContentCopy } from "react-icons/md"

export const template = [
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
]

export const RecentUpdate = [
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
]
export const AccessApi = [
  {
    id: 1,
    access: "Access Api",
    title: "jacket",
    limit: "NA",
    time: "May 22 2024 | 12:59 PM",
  },
  {
    id: 2,
    access: "Access Api",
    title: "Marriage",
    limit: "NA",
    time: "May 22 2024 | 12:59 PM",
  },
  {
    id: 3,
    access: "Access Api",
    title: "Cooking",
    limit: "NA",
    time: "May 22 2024 | 12:59 PM",
  },
  {
    id: 5,
    access: "Access Api",
    title: "Cooking",
    limit: "NA",
    time: "May 22 2024 | 12:59 PM",
  },
  {
    id: 6,
    access: "Access Api",
    title: "Cooking",
    limit: "NA",
    time: "May 22 2024 | 12:59 PM",
  },
]
export const reports = [
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
]

export const senderReport = [
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },

  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },

  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
]

export const Chat = [
  {
    id: 1,
    type: "option",
    question: "Hello! How can I help you? , today",
    answer: "Hello,i’m fine,how can i help you? fine,how can i help you?",
  },
  {
    id: 1,
    type: "option",
    question: "Hello! How can I help you? , today",
    answer: "Hello,i’m fine,how can i help you? fine,how can i help you?",
  },
  {
    id: 1,
    type: "option",
    question: "Hello! How can I help you? , today",
    answer: "Hello,i’m fine,how can i help you? fine,how can i help you?",
  },

  {
    id: 1,
    type: "chat",
    question: "Hello! How can I help you? , today",
    answer: "Hello,i’m fine,how can i help you? fine,how can i help you?",
  },
  {
    id: 1,
    type: "chat",
    question: "Hello! How can I help you? , today",
    answer: "Hello,i’m fine,how can i help you? fine,how can i help you?",
  },
]

export const ButtonType = [
  {
    type: "quick",
    fields: [
      {
        id: 1,
        line1: "Marketing Opt-Out",
        line2: "recommend",
        btnIcon: <BsFillReplyFill />,
        btnTitle: "Quick Reply",
      },
      {
        id: 2,
        line1: "Custom",
        line2: "",
        btnIcon: <BsFillReplyFill />,
        btnTitle: "Quick Reply",
      },
    ],
  },
  {
    type: "action",
    fields: [
      {
        line1: "Visit Website",
        line2: "2 button maximum",
        btnIcon: <PiArrowSquareInLight />,
        btnTitle: "Visit Website",
      },
      {
        line1: "Call Phone Number",
        line2: "1 button maximum",
        btnIcon: <MdCall />,
        btnTitle: "Call Phone Number",
      },
      {
        line1: "Complete Flow",
        line2: "1 button maximum",
        btnIcon: "",
        btnTitle: "Complete Flow",
      },
      {
        line1: "Copy Offer Code",
        line2: "1 button maximum",
        btnIcon: <MdOutlineContentCopy />,
        btnTitle: "Copy Offer Code",
      },
    ],
  },
]

export const ActionBtnOption = [
  {
    id: 1,
    option: "Call Phone Number",
  },
  {
    id: 2,
    option: "Copy Offer Code",
  },
  {
    id: 3,
    option: "Visit Website",
  },
  {
    id: 4,
    option: "Complete Flow",
  },
]

export const InputFormat = [
  {
    id: 1,
    option: "None",
    icon: null,
  },
  {
    id: 2,
    option: "Text",
    icon: <FaTextHeight />,
  },
  {
    id: 3,
    option: "Image",
    icon: <FaImage />,
  },
  {
    id: 4,
    option: "Video",
    icon: <FaVideo />,
  },
  {
    id: 5,
    option: "Document",
    icon: <FaFileAlt />,
  },
  {
    id: 6,
    option: "Location",
    icon: <FaMapMarkerAlt />,
  },
]
