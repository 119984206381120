import { useNavigate } from "react-router-dom"

export const useScrollToTopAndNavigate = (path) => {
  const navigate = useNavigate()

  const handleGoToTopClick = () => {
    console.log("call function")

    window.scrollTo({ top: 0, behavior: "smooth" })
    navigate(path) // Redirect to specified path
  }

  return handleGoToTopClick
}
