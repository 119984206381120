import React from "react"
import "../style/Footer.css"
import logo from "../images/static home/footerlogo.png"
import { CiMail } from "react-icons/ci"
import { FaFacebook, FaTwitter } from "react-icons/fa"
import { CiInstagram } from "react-icons/ci"
import { FaGithub } from "react-icons/fa"
import { Link, useNavigate } from "react-router-dom"
import { useScrollToTopAndNavigate } from "../utils/utils.jsx"
import { useUIContext } from "../context/BasicProvider.jsx"

const Footer = () => {
  const { setSelectedNavbar } = useUIContext()
  const handleGoToTopClick = useScrollToTopAndNavigate("/")
  const navigate = useNavigate()
  const handleTofeture = (event, target) => {
    setSelectedNavbar(target)
    event.preventDefault()
    navigate("/")
    setTimeout(() => {
      scrollToTarget(target)
    }, 100)
  }

  const scrollToTarget = (target) => {
    const element = document.getElementById(target)
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 50,
        behavior: "smooth",
      })
    }
  }
  return (
    <footer className="footer">
      <div className="main-footer">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={logo} alt="Logo" className="logo" />
          </div>
          <p className="footer-text">
            Our chatbot is designed to provide you with instant support and
            assistance 24/7. Whether you have questions about our services, need help
            navigating our site, or require support with a specific issue, our
            intelligent chatbot is here to help.{" "}
            <span className="read">Learn More</span>
          </p>
          <div className="footer-icons">
            <div className="footer-icon">
              <CiMail size={30} />
              <div className="details">
                <p>Email</p>
                <p className="orange"> info@chatbot.buzz</p>
              </div>
            </div>
          </div>
        </div>
        <div className="social-icon-footer">
          <div className="social-footer ">
            <FaTwitter />
          </div>
          <div className="social-footer ">
            <FaFacebook />
          </div>

          <div className="social-footer ">
            <CiInstagram />
          </div>
          <div className="social-footer ">
            <FaGithub />
          </div>
        </div>
      </div>
      <hr className="footer-divider" />
      <div className="bottom-part">
        <ul className="footer-li">
          <button
            onClick={() => {
              handleGoToTopClick()
              setSelectedNavbar("home")
            }}
          >
            Home
          </button>
          <button onClick={(e) => handleTofeture(e, "features")}>Features</button>
          <li>
            {" "}
            <Link
              onClick={() => {
                handleGoToTopClick()
                setSelectedNavbar("contact")
              }}
              to="/contact"
            >
              Contact Us
            </Link>
          </li>
        </ul>
        <div className="footer-copyright">© 2000-2021, All Rights Reserved</div>
      </div>
    </footer>
  )
}

export default Footer
