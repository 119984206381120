import React from "react"
import Hero from "./Hero.jsx"
import Features from "./Features.jsx"
import Payment from "./Faq.jsx"
import Channel from "./Channel.jsx"
import "../style/Comman.css"
import GoToTop from "./GoToTop.jsx"

const Home = () => {
  return (
    <>
      <GoToTop />
      <Hero />
      <Features />
      <Payment />
      <Channel />
    </>
  )
}

export default Home
