import { toast } from "react-toastify"
import { all, call, put, takeLatest } from "redux-saga/effects"
import authServices from "../../services/auth.services.jsx"
import { LOGIN_REQUEST, LOGIN_LOADING, LOGOUT_REQUEST } from "./authAction.jsx"

function* authLogin(action) {
  try {
    yield put({ type: LOGIN_LOADING, payload: true })
    const { data } = yield call(authServices.authLogin, action.payload)
    localStorage.setItem("access_token", data?.access_token)
    window.location.href = "/dashboard"
    toast.success("Login successfully done")
  } catch (error) {
    toast.error(error?.response?.data?.message)
  } finally {
    yield put({ type: LOGIN_LOADING, payload: false })
  }
}
function* authLogout() {
  try {
    yield call(authServices.authLogout)
    localStorage.clear()
    sessionStorage.clear()
    toast.success("Login successfully done")
    window.location.href = "/login"
  } catch (error) {
    toast.error(error?.response?.data?.message)
  }
}

function* authSaga() {
  yield all([
    takeLatest(LOGIN_REQUEST, authLogin),
    takeLatest(LOGOUT_REQUEST, authLogout),
  ])
}

export default authSaga
